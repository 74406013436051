import debounce from 'lodash.debounce';
import * as Sentry from '@sentry/browser';

export default class Navigation {
    constructor() {
        this.breakpoint = 1200;
        this.navigation = document.querySelector('[data-navigation]');
        this.debounceToggleFlyout = debounce(this.toggleFlyout.bind(this), 100);
        this.menuIsOpen = false;
        this.currentFlyout = null;
        this.isMobile = window.innerWidth < this.breakpoint;
        this.animationStacks = [];
        this.reducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;

        if (!this.navigation) {
            Sentry.captureMessage('Navigation element not found');
            return;
        }

        try {
            this.init();
        } catch (error) {
            Sentry.captureException(error);
        }
    }

    init() {
        this.initMenuLinks();
        this.initMobileMenu();
        this.initDesktopFlyout();
        this.initKeyboard();
        this.initMoreButtons();
        this.initBackButtons();
        this.initViewportListener();
        this.initBackdrop();
    }

    initMenuLinks() {
        const mainMenuList = this.navigation.querySelector('ul');
        const menuItems = this.navigation.querySelectorAll('li');

        menuItems.forEach((li) => {
            const hasAriaHaspopup = li.querySelector('a[aria-haspopup="true"]');

            if (hasAriaHaspopup) {
                const list = li.closest('ul');
                if (!list || list === mainMenuList) return;
                li.addEventListener('mouseenter', this.handleMouseEnter.bind(this));
            }
        });
    }

    initViewportListener() {
        window.addEventListener('resize', debounce(() => {
            const isMobile = window.innerWidth < this.breakpoint;
            if (this.isMobile === isMobile) return;
            this.isMobile = isMobile;

            this.cancelAllAnimations();
            this.closeChildren();
        }, 300));
    }

    initMobileMenu() {
        this.navigation.querySelectorAll('[aria-haspopup="true"]').forEach((el) => {
            el.addEventListener('click', this.handleClick.bind(this));
        });
        document.querySelector('.navigation-mobile-button .toggle-btn').addEventListener('click', this.handleBurgerMenuButtonClick.bind(this));
    }

    initMoreButtons() {
        this.navigation.querySelectorAll('[data-navigation-more]').forEach((el) => {
            el.addEventListener('click', this.handleClickMore.bind(this));
        });
    }

    initBackButtons() {
        this.navigation.querySelectorAll('[data-navigation-back]').forEach((el) => {
            el.addEventListener('click', this.handleClickBack.bind(this));
        });
    }

    initBackdrop() {
        if (!this.isMobile) return;
        const backdrop = document.querySelector('[data-navigation-backdrop]');

        backdrop.addEventListener('click', () => {
            this.closeMobileMenu();
        });
    }

    initKeyboard() {
        document.addEventListener('keydown', this.handleKeyboard.bind(this));
    }

    handleKeyboard(event) {
        if (event.key === 'Enter') {
            this.handleEnterKey(event);
        }
        if (event.key === 'Escape') {
            this.handleEscapeKey(event);
        }
    }

    handleEscapeKey() {
        const {activeElement} = document;
        if (!activeElement) return;
        const activeMenu = activeElement.closest('[aria-labelledby].is-open');
        if (!activeMenu) {
            this.closeChildren();
            activeElement.blur();
            return;
        }
        const target = this.navigation.querySelector(`#${activeMenu.getAttribute('aria-labelledby')}`);
        this.openElement(target);
        target.focus();
    }

    handleEnterKey(event) {
        const {activeElement} = document;
        if (!activeElement) return;
        const liElement = activeElement.closest('li');
        if (!liElement) return;
        const activeMenu = liElement.querySelector('[aria-haspopup="true"]');
        if (!activeMenu) return;
        const submenu = this.openElement(activeMenu);
        if (!submenu) return;
        submenu.querySelector('a:not([data-navigation-back])').focus();
        this.openFlyout(submenu.closest('[data-navigation-flyout]'), event);
    }

    initDesktopFlyout() {
        this.navigation.querySelector('ul').querySelectorAll(':scope > li').forEach((li) => {
            const flyout = li.querySelector('[data-navigation-flyout]');
            if (!flyout) return;
            li.addEventListener('mouseenter', this.debounceToggleFlyout.bind(this, flyout));
            li.addEventListener('mouseleave', this.debounceToggleFlyout.bind(this, flyout));
        });
        this.navigation.querySelectorAll('[data-navigation-flyout]').forEach((el) => {
            el.addEventListener('focusout', this.handleFocusOut.bind(this));
        });
    }

    handleClick(event) {
        event.preventDefault();
        if (this.isMobile) {
            const target = this.navigation.querySelector(event.target.getAttribute('href'));
            this.focusSlide(target);
        }
        this.openElement(event.target);
    }

    handleMouseEnter(event) {
        this.openElement(event.target.querySelector('[aria-haspopup="true"]'));
    }

    toggleFlyout(flyout, event) {
        if (event.type === 'mouseenter' && flyout) {
            if (this.currentFlyout !== flyout) {
                this.closeFlyout(this.currentFlyout);
                this.currentFlyout = flyout;
            }
            this.openFlyout(flyout, event);
            return;
        }
        this.closeFlyout(flyout);
    }

    openFlyout(flyout, event) {
        if (this.isMobile || !flyout) return;

        this.cancelAllAnimations();
        this.activeBackdrop();

        if (!flyout.classList.contains('is-open')) {
            this.openElement(event.target.querySelector('[aria-haspopup="true"]'));
            this.animationStacks.push(this.fadeIn(flyout));
            const submenu = flyout.querySelectorAll('[aria-haspopup="true"]');
            if (submenu.length > 0) {
                this.openElement(submenu[0]);
            }
        }
    }

    closeFlyout(flyout) {
        if (this.isMobile || !flyout) return;

        if (flyout.classList.contains('is-open')) {
            this.animationStacks.push(this.fadeOut(flyout));
        }
        this.closeChildren();
        this.removeBackdrop();
    }

    handleClickMore(event) {
        event.preventDefault();
        const el = event.target;
        const list = el.closest('li');

        const submenu = list.querySelector('ul');
        submenu.classList.add('is-open');
        submenu.querySelector('a').focus();

        el.setAttribute('aria-expanded', 'true');
    }

    handleClickBack(event) {
        event.preventDefault();
        const trigger = event.target;
        const target = this.navigation.querySelector(trigger.getAttribute('href'));
        const currentOpened = trigger.closest('li').querySelector('[aria-expanded="true"]');

        this.focusSlide(target);

        if (!currentOpened) return;
        this.closeElement(currentOpened);
    }

    handleBurgerMenuButtonClick(e) {
        if (!this.isMobile) return;
        const isExpanded = e.target.closest('[aria-expanded]').getAttribute('aria-expanded') === 'true';

        if (!isExpanded) {
            this.openMobileMenu();
        } else {
            this.closeMobileMenu();
        }
    }

    handleFocusOut(event) {
        if (this.isMobile) return;
        const flyout = event.currentTarget;

        if (event.relatedTarget && !flyout.contains(event.relatedTarget)) {
            const liElement = flyout.closest('li');
            if (liElement) {
                this.closeElement(liElement.querySelector('[aria-haspopup="true"]'));
            }
        }
    }

    closeChildren(parent = null) {
        const root = parent || this.navigation;
        const lists = new Set();

        root.querySelectorAll('[aria-haspopup="true"][aria-expanded="true"]').forEach(el => {
            const list = el.closest('ul');
            if (list.hasAttribute('data-ensure-one-expanded') && el === list.children[0].querySelector('a')) return;

            this.closeElement(el);
            lists.add(list);
        });

        lists.forEach(list => {
            if (list.hasAttribute('data-ensure-one-expanded')) {
                const first = list.querySelector('[aria-haspopup="true"]');
                this.animationStacks.last?.finished.then(() => this.openElement(first)).catch(() => {
                });
            }
        });
    }

    openMobileMenu() {
        if (!this.isMobile) return;
        this.menuIsOpen = true;

        document.querySelector('.navigation-mobile-button .toggle-btn').setAttribute('aria-expanded', 'true');
        document.querySelector('.navigation-mobile-button .toggle-icon').classList.add('active');
        this.activeBackdrop();

        this.navigation.classList.add('is-open');
        this.navigation.querySelector('[data-navigation-slide="0"]').classList.add('is-open');
        this.animationStacks.push(this.openingMobileMenu());
    }

    closeMobileMenu() {
        const slide0 = this.navigation.querySelector('[data-navigation-slide="0"]');
        this.menuIsOpen = false;

        if (!this.isMobile) return;
        const animation = this.closingMobileMenu();
        animation.addEventListener('finish', () => {
            if (this.menuIsOpen) return;
            this.navigation.classList.remove('is-open');
            this.focusSlide(slide0);
            slide0.classList.remove('is-open');
            this.closeChildren();
        });
        this.animationStacks.push(animation);

        this.removeBackdrop();
        document.querySelector('.navigation-mobile-button .toggle-btn').setAttribute('aria-expanded', 'false');
        document.querySelector('.navigation-mobile-button .toggle-icon').classList.remove('active');
    }

    openElement(el) {
        if (!el || !(el instanceof Element)) return null;

        if (el.getAttribute('aria-haspopup') === 'true') {
            el.setAttribute('aria-expanded', 'true');
        }

        const submenu = this.navigation.querySelector(el.getAttribute('href'));
        if (submenu) {
            submenu.classList.add('is-open');
        }

        this.closeSiblingElements(el);

        return submenu;
    }

    closeSiblingElements(el) {
        const siblingsItems = Array.from(el.closest('ul').children).filter((child) => child.querySelector('a[aria-expanded="true"]') && child !== el.closest('li'));

        siblingsItems.forEach((item) => {
            const handler = Array.from(item.children).find((child) => child.getAttribute('aria-haspopup') && child.getAttribute('aria-expanded') && child !== el);
            this.closeElement(handler, false);
        });
    }

    closeElement(el, waitForAnimationToEnd = true) {
        if (!el || !(el instanceof Element)) return;

        const lastAnimation = this.animationStacks.at(-1);
        if (waitForAnimationToEnd && lastAnimation?.playState === 'running') {
            lastAnimation.finished.then(this.closeElement.bind(this, el)).catch(() => {
            });
            return;
        }

        const submenuId = el.getAttribute('href');
        if (!submenuId) return;

        const submenu = this.navigation.querySelector(submenuId);
        if (!submenu) return;

        el.setAttribute('aria-expanded', 'false');
        submenu.classList.remove('is-open');
        if (document.activeElement === el) {
            document.activeElement.blur();
        }

        this.closeChildren(submenu);
    }

    getClosestSlide(el) {
        if (!el || !(el instanceof Element)) return null;
        if (el.hasAttribute('data-navigation-slide')) {
            return el;
        }

        return el.querySelector('[data-navigation-slide]') || el.closest('[data-navigation-slide]');
    }

    focusSlide(el) {
        if (!this.isMobile) return;
        if (!el || !(el instanceof Element)) return;

        const slide = this.getClosestSlide(el);

        slide.classList.add('is-open');

        const slider = this.navigation.querySelector('[data-navigation-slider]');
        const animation = slider.animate([
            {transform: `translateX(calc(-${slide.dataset.navigationSlide} * var(--mobile-width)))`},
        ], {
            duration: this.duration(300),
            easing: 'ease-out',
            fill: 'forwards',
        });
        animation.addEventListener('finish', () => {
            this.navigation.querySelectorAll('[data-navigation-slide]').forEach((child) => {
                if (child !== slide && !child.contains(slide)) {
                    child.classList.remove('is-open');
                }
            });
        });
        this.animationStacks.push(animation);
    }

    duration(defaultDuration) {
        return this.reducedMotion ? 0.1 : defaultDuration;
    }

    openingMobileMenu() {
        return this.navigation.animate([
            {transform: 'translateX(0)'},
        ], {
            duration: this.duration(300),
            easing: 'ease-out',
            fill: 'forwards',
        });
    }

    closingMobileMenu() {
        return this.navigation.animate([
            {transform: 'translateX(100%)'},
        ], {
            duration: this.duration(300),
            easing: 'ease-out',
            fill: 'forwards',
        });
    }

    activeBackdrop() {
        const backdrop = document.querySelector('[data-navigation-backdrop]');

        if (backdrop.classList.contains('active')) return;

        backdrop.classList.add('active');
        const animation = this.fadeIn(backdrop);
        this.animationStacks.push(animation);
    }

    removeBackdrop() {
        const backdrop = document.querySelector('[data-navigation-backdrop]');

        if (!backdrop.classList.contains('active')) return;

        const animation = this.fadeOut(backdrop);
        animation.finished.then(() => {
            if (!this.menuIsOpen) {
                backdrop.classList.remove('active');
            }
        }).catch(() => {
        });
        this.animationStacks.push(animation);
    }

    fadeIn(el) {
        if (!el) return;
        return el.animate([
            {opacity: 0},
            {opacity: 1},
        ], {
            duration: this.duration(300),
            easing: 'ease-out',
            fill: 'forwards',
        });
    }

    fadeOut(el) {
        if (!el) return;
        return el.animate([
            {opacity: 1},
            {opacity: 0},
        ], {
            duration: this.duration(100),
            easing: 'ease-out',
            fill: 'forwards',
        });
    }

    cancelAllAnimations() {
        this.animationStacks.forEach(animation => animation?.cancel());
        this.animationStacks = [];
    }
}
