import abTest from '../utils/abTest';

export default function () {
    const testId = 'mt3301';

    const runTest = () => {
        const oldContactBanner = document.querySelector('[data-contact-banner]');
        const contactBanner = document.querySelector(`[data-${testId}].contact-banner-mt3301__wrapper`);

        const oldBreadcrumbs = document.querySelector('[data-breadcrumbs]');
        const breadcrumbs = document.querySelector(`[data-${testId}].breadcrumbs-mt3301__wrapper`);
        const footerGradient = document.querySelector(`[data-${testId}].footer__gradient`);

        if (oldContactBanner) {
            oldContactBanner.style.display = 'none';
        }

        if (contactBanner) {
            contactBanner.style.display = 'block';
        }

        if (footerGradient) {
            footerGradient.style.display = 'block';
        }

        if (oldBreadcrumbs && breadcrumbs) {
            oldBreadcrumbs.style.display = 'none';
            breadcrumbs.style.display = 'block';
        }
    };

    abTest(runTest, testId);
}
