export default function engagementOnPageLeadForm(forms) {
    const handleChoiceClick = (choice, form) => {
        const {
            engagementOnPageLeadFormIsSelected: isSelected,
            engagementOnPageLeadFormQuestion: question,
            engagementOnPageLeadFormTargetPage: targetPage,
        } = form.dataset;

        if (isSelected) {
            return; // Block double selection
        }

        form.setAttribute('data-engagement-on-page-lead-form-is-selected', true);

        let checkmark;

        checkmark = choice.querySelector('.checkmark__line');
        checkmark.classList.add('checkmark__line--checked');

        setTimeout(() => {
            const loader = form.querySelector('.loader-overlay');
            loader.style.display = 'block';

            const fullUrl = new URL(targetPage);
            fullUrl.searchParams.set(question, choice.dataset.param);

            window.location.href = fullUrl.toString();
        }, checkmark || isAbTest ? 400 : 0); // Wait for checkmark animation
    };

    forms.forEach((form) => {
        const choices = [
            ...form.querySelectorAll('button[data-param]'),
        ];

        choices.forEach((choice) => {
            // Show checkmark then loader and redirect to lead form on click
            choice.addEventListener(
                'click', (event) => {
                    event.preventDefault();
                    handleChoiceClick(choice, form);
                },
            );
        });
    });

    // Some browser might return to the page without a reload via the back button
    window.addEventListener('pageshow', () => {
        // Find all selected lead forms
        const selectedForms = [
            ...document.querySelectorAll('[data-engagement-on-page-lead-form-is-selected]'),
        ];

        // Hide checkmark and loaders of active forms
        selectedForms.forEach((form) => {
            form.removeAttribute('data-engagement-on-page-lead-form-is-selected');

            const checkmark = form.querySelector('.checkmark__line--checked');
            checkmark.classList.remove('checkmark__line--checked');

            const loader = form.querySelector('.loader-overlay');
            loader.style.display = 'none';
        });
    });
}
