/**
 * AB test util.
 *
 * In cases when we want to run advanced AB tests with Visual Website Optimiser, we need to
 * move the test changes to the code, and just activate this logic on VWO's demand.
 *
 * This util helps to wait for VWO to be loaded and run a test if VWO wants.
 *
 * @param {function} runTest: Test callback function
 * @param {string} testId: The test ID, usually corresponding to the Jira ticket ID (mt123, mt456,
 * etc)
 *
 * ########################################
 * Here is an example on how to use it:

import abTest from '../utils/abTest';

export default function () {
    const runTest = () => {
        // do some magic
    };

    abTest(runTest, testId);
 }

 * ########################################
 */
export default function (runTest, testId) {
    if (window.TNS.config.abTest.queue.includes(testId)) {
        // Test has already been queued by VWO script -- run it
        runTest();
    } else {
        // VWO script may not have been loaded yet -- prepare for later
        window.TNS.config.abTest.funcs[testId] = () => {
            runTest();
        };
    }
}
